import React from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'

import { AppPageProps } from '../../types'
import { BlockList } from '../../components/BlockList'
import { Layout } from '../../components/Layout'
import { LoadingContext } from '../../contexts/LoadingContext'
import { OpticalImage } from '../../components/OpticalImage'
import { PhilosophyBlock } from '../../components/PhilosophyBlock'
import { PhilosophyItem } from '../../components/PhilosophyItem'
import { PhilosophyRelatedList } from '../../components/PhilosophyRelatedList'
import { SectionList } from '../../components/SectionList'
import { SpiritPageEnQuery } from '../../../types/graphql-types'

export const query = graphql`
  query SpiritPageEn {
    heroMobile: file(
      relativePath: { eq: "images/philosophy-spirit-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(
      relativePath: { eq: "images/philosophy-spirit-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageHandmade: file(
      relativePath: { eq: "images/philosophy-handmade.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageDistillation: file(
      relativePath: { eq: "images/philosophy-distillation.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageLegend: file(relativePath: { eq: "images/philosophy-legend.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageVillage: file(relativePath: { eq: "images/philosophy-village.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageLiquor: file(relativePath: { eq: "images/philosophy-liquor.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landMobile: file(
      relativePath: { eq: "images/philosophy-land-hero@mobile.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    landTablet: file(
      relativePath: { eq: "images/philosophy-land-hero@tablet.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

interface SpiritPageProps extends AppPageProps {
  data: SpiritPageEnQuery
}

const SpiritPage: React.FC<SpiritPageProps> = ({
  pathContext: { langKey },
  data: {
    heroMobile,
    heroTablet,
    imageHandmade,
    imageDistillation,
    imageLegend,
    imageVillage,
    imageLiquor,
    landMobile,
    landTablet,
  },
}) => (
  <Layout
    langKey={langKey}
    pageTitle="Spirit"
    // eslint-disable-next-line max-len
    pageDescription="At Osuzuyama Distillery, we use our hands for all processes that require care and attention, such as growing koji and malt. Our master craftsmen spread koji inside the cellars and ferment them at the most suitable temperature. While we use some of the latest technologies to help the process, we continue to pursue handmade flavor and quality in our craft."
    titleLabel="Spirit"
  >
    <SectionList>
      <BlockList>
        <LoadingContext.Provider value="eager">
          <OpticalImage
            fluidMobile={heroMobile?.childImageSharp?.fluid as FluidObject}
            fluidTablet={heroTablet?.childImageSharp?.fluid as FluidObject}
            sizes="100vw"
          />
        </LoadingContext.Provider>
        <PhilosophyBlock
          title="Handmade"
          fluid={imageHandmade?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            At Osuzuyama Distillery, we use our hands for all processes that
            require care and attention, such as growing koji and malt. Our
            master craftsmen spread koji inside the cellars and ferment them at
            the most suitable temperature. While we use some of the latest
            technologies to help the process, we continue to pursue handmade
            flavor and quality in our craft.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="Distillation"
          fluid={imageDistillation?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            Distillation is the process of heating the moromi mash to a boiling
            point and then cooling the steam to extract liquid. As the alcohol
            content becomes more concentrated, the rich flavors are captured in
            the liquor. Distillation is a mystical and scientific process that
            has been part of the liquor- making craft since early human history.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="Legend of the Osuzu Sacred Horse"
          fluid={imageLegend?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            The name of Osuzu Mountain is said to originate from a local legend
            of the sacred horse. The story begins with a white horse that
            appeared on a farm at the foot of the mountain. This horse was known
            to be used by the mountain god on his visits to the shrine. One day,
            a young villager saw the mountain god riding across the sky on his
            horse. As the white horse galloped, the golden bell on its neck made
            a clear ringing sound, and with the horse’s neigh, it echoed across
            the sky in all directions. When the people of the village learned of
            this news, they started calling the mountain “Osuzu”(bell) and
            revered it as the sacred mountain where the god had returned with
            the white horse.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="New Village"
          fluid={imageVillage?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            At the foot of Osuzu Mountain, there is a village called
            “Atarashiki-mura” (New Village) on the right bank of the Omaru
            river. This village was founded in 1918 by the author Saneatsu
            Mushanokoji and his companions as a utopia that promotes humanism
            and individualism. Saneatsu Mushanokoji lived for six years in the
            rich natural environment at the foot of Osuzu mountain, composing
            his literary works while leading an agricultural lifestyle.
          </p>
        </PhilosophyBlock>
        <PhilosophyBlock
          title="The New Distilled Liquor"
          fluid={imageLiquor?.childImageSharp?.fluid as FluidObject}
        >
          <p>
            Our goal is to create a distilled liquor that is uniquely ours, that
            can only be achieved on this land. Looking back at the history of
            Osuzuyama Distillery, we realize that we had faced many challenges
            while we worked in pursuit of our goal. Shochu first came about when
            the fermentation culture of the Kyushu region chanced upon the
            distillation process that was introduced to Japan from abroad.
            Ingredients that are commonly used today, such as potato, barley,
            and rice, can also be traced back to their foreign origins. Despite
            its popularity and recognition today, shochu has a long history of
            evolution, and we believe that we still have a long way to go to
            realize its fullest potential. We will achieve the true essence of
            our liquor by continuously challenging ourselves to evolve in the
            rich, fertile lands of Miyazaki and Osuzu Mountain. Similarly, we
            want to nurture and embrace aged whisky made of germinated grains
            and spirits imbued with various botanical aromas as new types of
            distilled liquor that have come from this land. As a shochu
            distiller, our spirit lies in our devotion to this land and our
            fortitude to pursue new possibilities.
          </p>
        </PhilosophyBlock>
      </BlockList>
      <PhilosophyRelatedList>
        <PhilosophyItem
          to="/philosophy/land/"
          fluidMobile={landMobile?.childImageSharp?.fluid as FluidObject}
          fluidTablet={landTablet?.childImageSharp?.fluid as FluidObject}
          label="Land"
        />
      </PhilosophyRelatedList>
    </SectionList>
  </Layout>
)

export default SpiritPage
